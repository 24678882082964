@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.ShareJournalDialog {
  .share-controls {
    @include flex(8px);
    margin-top: 8px;

    .Button:first-child,
    .ButtonTooltip:first-child {
      flex-grow: 1;
    }
  }

  .hint {
    color: $secondary-L3;
    margin-top: 8px;
  }
}

.AccessControlBlock {
  @include flex-col(4px);

  .list {
    @include flex-col(4px);
  }
}

.AccessControlEntry {
  background-color: $secondary-D2;
  border: 1px solid $secondary-D1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
}

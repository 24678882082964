@use 'styles/colors' as *;

.TradeInfoMediaSection {
  .attachments-section__wrapper {
    position: relative;
  }

  .attachments-section {
    padding: 16px;
    border: 2px dashed $secondary;
    max-height: 300px;
    overflow-y: auto;

    input[type='file'] {
      position: absolute;
      inset: 0;
      background-color: $secondary;
      opacity: 0;
      pointer-events: none;
    }

    .file-drop-overlay {
      position: absolute;
      inset: 0;
      bottom: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba($secondary-D2, 0.9);
      color: $secondary-L2;
      font-size: 24px;
      pointer-events: none;
      border: 3px dashed $primary;
      opacity: 0;
      backdrop-filter: blur(8px);
    }

    &.dragged-over {
      input[type='file'] {
        pointer-events: all;
      }

      .file-drop-overlay {
        opacity: 1;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-bottom: 8px;
      color: $secondary-L3;

      .Button {
        padding: 0;
      }
    }

    .attachments {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 500px;
      margin: 0 auto;

      .Attachment {
        position: relative;
        width: 160px;
        height: 160px;
        border: 1px solid $secondary-D1;
        display: flex;
        align-items: center;
        justify-content: center;

        img.thumbnail {
          max-width: 100%;
          max-height: 100%;
        }

        .thumbnail-skeleton {
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          transition: all 0.2s;
        }

        div.hover-card {
          position: absolute;
          inset: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff1;
          opacity: 0;
          cursor: zoom-in;
        }

        button.delete {
          position: absolute;
          top: 4px;
          right: 4px;
          opacity: 0;
          z-index: 100;
        }

        &.loading {
          img {
            opacity: 0;
          }
          .thumbnail-skeleton {
            opacity: 1;
          }
        }

        &:hover {
          button.delete,
          div.hover-card {
            opacity: 1;
          }
        }
      }
    }

    .loaders {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 16px;

      .AttachmentLoader {
        background-color: $secondary-D4;

        .text {
          display: flex;
          justify-content: space-between;
          padding: 4px 8px;
        }

        .progress {
          background-color: #0004;
          .progress-bar {
            height: 4px;
            background-color: $primary;
            transition: all 0.1s;
          }
        }
      }
    }
  }
}

.PreviewImageItem {
  all: unset;
  cursor: pointer;
  height: 145px;
  width: 194px;
  flex-shrink: 0;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border: 1px solid $secondary;
  box-sizing: border-box;

  img.thumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.2s;
  }

  .thumbnail-skeleton {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: all 0.2s;
  }

  .Button.delete-button {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0;
  }

  &:hover {
    .Button.delete-button {
      opacity: 1;
    }
  }

  &.loading {
    img {
      opacity: 0;
    }
    .thumbnail-skeleton {
      opacity: 1;
    }
  }
}

.TradeNotePreviewImageItemModal {
  max-width: 90vw;
  max-height: 90vh;
  position: relative;

  img.full {
    max-width: 90vw;
    max-height: 90vh;
    position: relative;
  }

  img.thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .Button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

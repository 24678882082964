@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.Filters {
  background-color: $secondary-D3;
  border: 1px solid $secondary-D1;
  height: auto;
  padding: 8px;
  position: relative;

  display: flex;
  justify-content: space-between;

  transition: all 0.1s;
  max-width: 100%;

  &.scrolled {
    box-shadow: 0 5px 15px #0008;
  }

  .filters {
    scrollbar-width: thin;
    padding-right: 8px;

    &__content {
      display: grid;
      gap: 8px;
      grid-template-columns: 160px 160px min-content 160px min-content 160px;
      align-items: center;
    }

    div.label {
      color: $secondary-L3;
      width: fit-content;
    }

    .filter {
      @include flex-center(8px);
      min-width: 100px;

      &.MultiselectDropdown,
      &.SimpleDropdown {
        .Floating {
          width: 100%;
        }
        min-width: 100%;
      }
    }
  }

  .control-buttons {
    padding-left: 8px;
    border-left: 1px solid $secondary-D1;

    button:not(.MenuButton) {
      width: 150px;
      padding: 0px;
    }
  }

  & > div {
    display: flex;
    gap: 8px;
  }

  .SimpleDropdown,
  .MultiselectDropdown,
  .DateInput {
    width: 100%;
  }
}

.filters-mid-item-divider {
  background-color: $secondary-D2;
  padding: 4px 16px;
  position: sticky;
  top: -5px;
  font-size: 12px;
  font-weight: 700;
  color: $secondary-L3;
  text-transform: uppercase;
}

.journal-filter-centered {
  display: flex;
  gap: 6px;
  align-items: center;
}

@media (max-width: 1260px) {
  .Filters {
    display: grid;
    grid-template-columns: 730px 1fr;
    .control-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      button {
        width: 100%;
        .big {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1104px) {
  .Filters {
    .control-buttons {
      grid-template-columns: 2fr 1fr 1fr;

      button {
        .medium {
          display: none;
        }
      }

      & > .Button:first-child {
        .medium {
          display: inline;
        }
      }
    }
  }
}

@media (max-width: 954px) {
  .Filters {
    position: static !important;
    padding: 8px;
    grid-template-columns: 2fr 1fr;

    .filters {
      width: auto;
      padding: 0px 8px;

      &__content {
        width: 100%;
        grid-template-columns: 30px 1fr 30px 1fr;

        & > div:nth-child(1) {
          grid-column: span 2;
        }
        & > div:nth-child(2) {
          grid-column: span 2;
        }
      }
    }

    .control-buttons {
      border: none;
      padding: 0;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .Button {
        width: 100%;

        &:first-child {
          grid-column: span 2;
        }

        .medium {
          display: inline;
        }
        .big {
          display: inline;
        }
      }
    }
  }
}

@media (max-width: 660px) {
  .Filters {
    grid-template-columns: 410px 1fr;

    .control-buttons {
      .Button,
      .ButtonTooltip {
        .big {
          display: none;
        }

        .medium {
          display: none;
        }
      }

      & > *:first-child {
        .medium {
          display: inline;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .Filters {
    grid-template-columns: 1fr 120px;

    .filters {
      width: auto;
      padding: 0px 8px;

      &__content {
        width: 100%;
        grid-template-columns: 30px 1fr;
      }
    }

    .control-buttons {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;

      .Button,
      .ButtonTooltip {
        grid-column: span 1;

        .medium {
          display: inline;
        }
      }

      .Button:first-child {
        grid-column: span 1;
      }
    }
  }
}

@media (max-width: 480px) {
  .Filters {
    grid-template-columns: 1fr;

    .control-buttons {
      margin-top: 8px;
      grid-template-rows: auto;
    }
  }
}

@use 'styles/colors' as *;

.Dashboard {
  display: grid;
  grid-template-columns: 452px auto;
  grid-template-rows: 354px auto;
  gap: 8px;

  .Filters {
    position: sticky;
    top: 0px;
    z-index: 1000;
  }

  .Filters,
  .TradesTableSection,
  .WidgetsSection {
    grid-column: span 2;
  }
}

@media (max-width: 1000px) {
  .Dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: auto 354px 354px;

    .Filters,
    .TradesTableSection,
    .WidgetsSection {
      grid-column: span 1;
    }
  }
}

@media (max-width: 746px) {
  .Dashboard {
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .Dashboard {
    grid-template-rows: auto auto 354px;
  }
}

@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/forms' as *;

@import './_button-utils';

.Button {
  all: unset;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.36px;
  height: 32px;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  @include flex-center(4px);
  cursor: pointer;

  .content {
    position: relative;

    .children {
      @include flex-center(4px);
      transition: all 0.1s;
    }

    .loader {
      height: 6px;
      width: 40px;
      background-color: #fff5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.2, 1.5);
      opacity: 0;
      transition: all 0.1s;

      .loader-ball {
        position: absolute;
        height: 6px;
        width: 16px;
        background: linear-gradient(90deg, #fff0, #fffa 50%, #fff0 100%);
        margin-right: 4px;
        animation: button-loader 0.4s infinite linear;
      }
    }

    &.loading {
      .children {
        opacity: 0;
        transform: scale(0.7);
      }
      .loader {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &:disabled {
    cursor: auto;

    img.icon {
      opacity: 0.5;
    }
  }

  &:focus {
    outline: 2px solid #78bdfa;
    background-color: $secondary-D4;
  }

  /* TYPES */

  &.type-solid {
    &.color-primary {
      @include solid($primary, $primary-L1, $primary-L3, $primary-disable);
    }
    &.color-secondary {
      @include solid($secondary, $secondary-L1, $secondary-L2, $secondary-D2);
    }
    &.color-error {
      @include solid($red, $red-L1, $red-L1, $red-disabled-D1);
    }
    &.color-success {
      @include solid($green-D1, $green, $green, $green-D3);
    }
  }

  &.type-outline {
    &.color-primary {
      color: $primary-L3;
    }
    &.color-secondary {
      color: $white;
    }
    &.color-error {
      color: $red-text;
    }
    &.color-success {
      color: $green;
    }

    @include outline($secondary, $secondary-L1, $secondary-L1);
    @include solid(transparent, $secondary-D1, $secondary, transparent);

    &:disabled {
      @include outline($secondary-D1-disabled, $secondary-D1-disabled, $secondary-D1-disabled);
    }
  }

  &.type-text {
    &.color-primary {
      color: $primary-L3;
    }

    @include solid(transparent, $secondary-D1, $secondary, transparent);
  }

  &.type-link {
    &.color-secondary {
      @include link($white, $secondary-L5);
    }

    &.color-error {
      @include link($red-text, $red-L1);
    }

    &.color-primary {
      @include link($primary-L3, $primary-L2);
    }
  }

  &.type-input-trigger {
    @include general-input();
    font-weight: 400;
  }

  &.type-menu-item {
    @include solid(transparent, $secondary, $secondary-L1, transparent);
    &:focus {
      outline: none;
    }
  }

  /* OTHER PROPS */

  &.size {
    &-small {
      height: 24px;
    }
    &-medium {
      height: 32px;
    }
    &-large {
      height: 40px;
    }
    &-auto {
      height: auto;
    }
  }

  &.square {
    padding: 0;

    &.size-small {
      width: 24px;
    }
    &.size-medium {
      width: 32px;
    }
    &.size-large {
      width: 40px;
    }
  }

  &.disable-padding {
    padding: 0;
  }

  &.align {
    &-start {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: flex-end;
    }
  }

  &.font-type {
    &-button {
      font-family: 'Assistant', sans-serif;
      font-weight: 600;
    }
    &-text {
      font-family: 'Nunito Sans', sans-serif;
    }
  }
}

.ButtonTooltip {
  .Button {
    width: 100%;
  }
}

@keyframes button-loader {
  0% {
    left: 0;
    opacity: 0;
  }
  50% {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
    opacity: 0;
  }
}

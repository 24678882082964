@use 'styles/colors' as *;

.TradeInfoNoteTextSection {
  .NoteTextPreview {
    display: flex;
    border: 1px solid $secondary-D2;
    cursor: text;
    padding: 4px 8px;

    .text {
      flex-grow: 1;

      .placeholder {
        color: $secondary-L3;
        font-style: italic;
      }
    }

    .side-panel {
      background-color: $secondary-D2;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      opacity: 1;
      margin: -4px -8px;
      margin-left: 4px;

      .Icon {
        opacity: 0.5;
      }
    }

    &:hover {
      border-color: $secondary;

      .side-panel {
        background-color: $secondary;

        .Icon {
          opacity: 1;
        }
      }
    }
  }

  .TradeInfoNoteForm {
    display: flex;
    flex-direction: column;

    textarea {
      width: 100%;
      resize: vertical;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      background-color: $secondary-D2;
      border-top: none;
      padding: 8px;
      gap: 8px;

      button {
        min-width: 100px;
      }
    }
  }
}

/* === MAIN === */
$primary-L3: #78bdfa;
$primary-L2: #45a3f5;
$primary-L1: #1f96ff;
$primary: #0080f0;
$primary-disabled: #234461;
$primary-D1: #005eb1;
$primary-D2: #004989;
$primary-D3: #003869;
$primary-D4: #0f283d;
$primary-D5: #0f283d;
$primary-disable: #234461;

$secondary-L5: #eaedef;
$secondary-L4: #bdc7cb;
$secondary-L3: #9eacb2;
$secondary-L2: #6e8792;
$secondary-L1: #54707a;
$secondary-L1-disabled: #838b8f;
$secondary: #39525b;
$secondary-disabled: #656d70;
$secondary-D1: #2d3e43;
$secondary-D1-disabled: #3c484d;
$secondary-D2: #253237;
$secondary-D2-disabled: #2e393d;
$secondary-D2-transparent: #2532378f;
$secondary-D3: #1d282b;
$secondary-D4: #161e21;

/* === ADDITIONAL === */
$green: #1fdb51;
$green-D1: #158940;
$green-D2: #4c7a57;
$green-D3: #264d2f;
$green-D3: #16381e;

$yellow: #ffd12d;
$yellow-D1: #454223;
$yellow-D2: #2e2c13;

$orange-L1: #f5cda6;
$orange: #e57d1c;
$orange-D1: #291400;
$orange-text: #f09c4d;

$red-L1: #f53b54;
$red: #eb1734;
$red-disabled-L1: #a84d5a;
$red-disabled-D1: #5c2e31;
$red-text: #ff6b7f;

$purple: #ae73e1;
$purple-D1: #b162f5;

$white: #ffffff;
$bg-disable: #3c484d;
$stroke-disable: var(--color-stroke-disable);

$text-main: #ffffff;
$text-secondary: #9eacb2;
$text-disable-dark: #4c5b61;
$text-disable-light: #6e8792;

.color-secondary-l3 {
  color: $secondary-L3;
}
